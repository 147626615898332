import {
  FaceId,
  ProfileCircle,
  Label,
  SunLight,
  HalfMoon,
  ArrowLeftCircle,
  Menu,
  AddPage,
  EditPencil,
  DocSearch,
  Calendar,
  Text,
  ElectronicsChip,
  ShareIos,
  Mic,
  SaveFloppyDisk,
  HomeShield,
  EyeEmpty,
  AddUser,
  UserSquare,
  Page,
  NavArrowDown,
  NavArrowUp,
  Trash,
  InfoEmpty,
  Link,
  CloudUpload,
  Copy,
  GraphUp,
  Map,
  AddCircle,
  Group,
  MapsArrowDiagonal,
  StatsReport,
  ZoomIn,
  ZoomOut,
} from 'iconoir-react'

export const IdentifierIcon = ({ ...props }): JSX.Element => <FaceId {...props} />
export const AvatarIconLoading = ({ ...props }): JSX.Element => <ProfileCircle {...props} />
export const LabelIcon = ({ ...props }): JSX.Element => <Label {...props} />
export const SunIcon = ({ ...props }): JSX.Element => <SunLight {...props} />
export const MoonIcon = ({ ...props }): JSX.Element => <HalfMoon {...props} />
export const BackIcon = ({ ...props }): JSX.Element => <ArrowLeftCircle {...props} />
export const MenuIcon = ({ ...props }): JSX.Element => <Menu {...props} />
export const UserIcon = ({ ...props }): JSX.Element => <ProfileCircle {...props} />
export const AddIcon = ({ ...props }): JSX.Element => <AddPage {...props} />
export const EditIcon = ({ ...props }): JSX.Element => <EditPencil {...props} />
export const DocViewIcon = ({ ...props }): JSX.Element => <DocSearch {...props} />
export const CalendarIcon = ({ ...props }): JSX.Element => <Calendar {...props} />
export const TextIcon = ({ ...props }): JSX.Element => <Text {...props} />
export const ChipIcon = ({ ...props }): JSX.Element => <ElectronicsChip {...props} />
export const AnalyzeIcon = ({ ...props }): JSX.Element => <ShareIos {...props} />
export const MicIcon = ({ ...props }): JSX.Element => <Mic {...props} />
export const SaveIcon = ({ ...props }): JSX.Element => <SaveFloppyDisk {...props} />
export const OrganizationIcon = ({ ...props }): JSX.Element => <HomeShield {...props} />
export const ViewIcon = ({ ...props }): JSX.Element => <EyeEmpty {...props} />
export const AddUserIcon = ({ ...props }): JSX.Element => <AddUser {...props} />
export const UserDocument = ({ ...props }): JSX.Element => <UserSquare {...props} />
export const PageIcon = ({ ...props }): JSX.Element => <Page {...props} />
export const ArrowDownIcon = ({ ...props }): JSX.Element => <NavArrowDown {...props} />
export const ArrowUpIcon = ({ ...props }): JSX.Element => <NavArrowUp {...props} />
export const DeleteIcon = ({ ...props }): JSX.Element => <Trash {...props} />
export const InfoIcon = ({ ...props }): JSX.Element => <InfoEmpty {...props} />
export const LinkIcon = ({ ...props }): JSX.Element => <Link {...props} />
export const ShareIcon = ({ ...props }): JSX.Element => <CloudUpload {...props} />
export const CopyLinkIcon = ({ ...props }): JSX.Element => <Copy {...props} />
export const GraphIcon = ({ ...props }): JSX.Element => <GraphUp {...props} />
export const MapIcon = ({ ...props }): JSX.Element => <Map {...props} />
export const AddIconOutline = ({ ...props }): JSX.Element => <AddCircle {...props} />
export const UsersIcon = ({ ...props }): JSX.Element => <Group {...props} />
export const MapArrowIcon = ({ ...props }): JSX.Element => <MapsArrowDiagonal {...props} />
export const ReportIcon = ({ ...props }): JSX.Element => <StatsReport {...props} />
export const ZoomInIcon = ({ ...props }): JSX.Element => <ZoomIn {...props} />
export const ZoomOutIcon = ({ ...props }): JSX.Element => <ZoomOut {...props} />
