import Footer from '@/components/common/Footer'
import { type LayoutBaseProps as LayoutLoginProps } from '@/types'
import Head from 'next/head'

export default function LayoutLogin({ children, title = 'HumanAI - Login' }: LayoutLoginProps): JSX.Element {
  return (
    <div className="flex h-auto flex-col justify-center font-Montserrat transition-all delay-150 duration-700 ease-in-out md:h-[90vh]">
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width"
        />
        <meta
          name="description"
          content="HumanAI"
        />
        <meta
          name="keywords"
          content="HumanAI"
        />
        <meta
          name="author"
          content="HumanAI"
        />
        <meta
          name="robots"
          content="index, follow"
        />
        <meta
          name="googlebot"
          content="index, follow"
        />
        <meta
          name="google"
          content="notranslate"
        />
        <meta
          name="format-detection"
          content="telephone=no"
        />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/favicon.svg"
        />

        <link
          rel="icon"
          type="image/png"
          href="/favicon.png"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta
          name="msapplication-TileColor"
          content="#da532c"
        />
        <meta
          name="theme-color"
          content="#ffffff"
        />
      </Head>
      <main className="m-auto flex h-4/5 items-center justify-center md:mb-auto">{children}</main>
      <Footer
        showVersion={false}
        showThemeSwitch={false}
      />
    </div>
  )
}
